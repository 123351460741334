<!--用户登录-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="24" :md="22" :lg="22" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 新用户注册
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p login_bg">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="24" :md="22" :lg="22">
        <div class="reg_box">                    
          <div class="reg_tit">新用户注册</div>         
          <el-form v-if="!regOk" ref="formRef" :model="form" :rules="rules" size="large" label-width="100px">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" placeholder="请输入用户名" prefix-icon="User"></el-input>
            </el-form-item>
            <el-form-item label="密&nbsp;&nbsp;&nbsp;&nbsp;码" prop="password">
              <el-input v-model="form.password" show-password placeholder="请输入登录密码" prefix-icon="lock"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="repassword">
              <el-input v-model="form.repassword" show-password placeholder="请输入登录密码" prefix-icon="lock"></el-input>
            </el-form-item>
            <el-button type="primary" round @click="onRegist">注 册</el-button>
            <div class="login-bottom"><a  href="#"> 忘记密码</a> &nbsp; | &nbsp; <router-link to="/login">已注册登录</router-link></div>
          </el-form>
          <div v-else class="regok">
                恭喜！用户名为 <span>{{newUsername}}</span> 的新用户注册成功。<br /><br /><br />
                <router-link to="/login">立即登录</router-link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <router-link to="/">返回首页</router-link>
          </div>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>  
</template>

<script>
export default {
  name: 'regist',
  data () {
    var validateUser = async(rule, value, callback) => { 
      const params = '?username=' + value
      const { data: ret } = await this.$http.get(this.$api.userinfoByuname + params)
      if (ret.code === 1) {
        if (ret.data === null) {
          callback()
        } else {
          callback(new Error('用户名已有人使用，请重新换一个!'))
        }             
      }
      callback(new Error('用户名验证失败!'))      
    }
    var validatePass = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      regOk: false,
      newUsername: '',
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { validator: validateUser, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    onRegist () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在加载中...',
            // background: 'rgba(0, 0, 0, 0.7)'
          })
          // 重新生成json对象,去掉this.form.repassword
          // userType=2 企业用户
          const job = {
            username: this.form.username,
            password: this.form.password,
            deptId: '1',
            userType: '2'
          }
          const { data: ret } = await this.$http.post(this.$api.useradd, job)
          if (ret.code === 1) {
            this.regOk = true
            this.newUsername = job.username
          } else {
            this.$message.error(ret.msg)            
          }
          loading.close()
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })      
    }
  }
}
</script>

<style scoped>
.reg_box {
  margin: 0 auto;
  width: 70%;
  padding: 20px 40px;
  border: 1px solid #9FB5CA;
  background-color: #F4F7F9; 
  border-radius: 8px;
}
.el-form, .regok {  
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.regok {
  padding: 40px 0 ;
}
.regok span {
  font-size: 20px;
  color: #1D5CB9
}
.reg_tit {  
  font-size: 20px;
  color:#333;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  border-bottom: 2px solid #9FB5CA;
  margin-bottom: 20px;
}
.reg_box input { 
  padding: 0 0 0 50px;  
  height: 45px;
  width: 100%;    
  color: #999;
  border: 1px solid #C8D4E0;
  border-radius: 5px;        
}

.el-input{
  font-size: 16px;
}
.el-button{
  width: 100%;
  font-size: 16px;
}
.login-bottom {
  margin-top: 16px;
  text-align:right
}

</style>